<template>
   <div id="section-two">
      <div class="container mt-5 text-center">
         <div class="row">
            <h1 class="fw-bold">Sobre nós</h1>
         </div>

         <div class="row">
            <p class="sobre-licitalerta">
               Bem-vindo à <strong class="licitalerta">LicitAlerta</strong>, uma inovadora
               pioneira na transformação do cenário de licitações por meio da tecnologia.
               Fundada com a visão de proporcionar uma vantagem competitiva significativa
               para os fornecedores, nossa empresa se destaca ao trazer notificações de
               processos de licitações em tempo real.
            </p>
         </div>

         <div class="row mt-3">
            <div class="col-md-6 d-flex align-items-center">
               <p class="sobre-licitalerta">
                  Nosso aplicativo mobile revolucionário é projetado para trazer
                  eficiência e conveniência ao mundo das licitações. Ao cadastrar-se em
                  nossa plataforma, os fornecedores têm acesso a uma rede abrangente de
                  empresas de licitações, recebendo notificações em tempo real sobre novos
                  processos cadastrados. Isso permite que eles estejam sempre um passo à
                  frente da concorrência, maximizando suas oportunidades de negócios.
               </p>
            </div>
            <div class="col-md-6" data-aos="fade-top" data-aos-duration="1500">
               <img src="../assets/img/processos.png" alt="" />
            </div>
         </div>

         <div class="row">
            <div class="col-md-6" data-aos="fade-bottom"  data-aos-duration="1500">
               <img src="../assets/img/notificacao.png" class="" />
            </div>

            <div class="col-md-6 d-flex align-items-center">
               <p class="sobre-licitalerta">
                  Notificações Instantâneas: Mantenha-se informado sobre processos de
                  licitação assim que são cadastrados, garantindo que você nunca perca uma
                  oportunidade. Acesso a Múltiplas Empresas: Nossa plataforma abrange
                  diversas empresas de licitações, proporcionando uma visão abrangente do
                  cenário de oportunidades. Facilidade de Uso: Desenvolvemos uma interface
                  intuitiva e amigável, tornando a navegação e a utilização do aplicativo
                  uma experiência agradável. Transparência e Confiança: Valorizamos a
                  transparência em todas as interações. Nosso compromisso com a
                  integridade e confiança reflete-se em cada aspecto do nosso serviço.
               </p>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {};
</script>

<style scoped>
#section-two {
   margin-bottom: 20px;
}

.licitalerta {
   font-weight: bold;
   font-size: 22px;
   color: blueviolet;
}

.sobre-licitalerta {
   font-size: 18px;
}

img {
   width: 60%;
}

.invertida-horizontal {
  transform: scaleX(-1);
}
</style>
