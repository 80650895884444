<template>
  <header>
    <Header />
  </header>
  
  <main>
    <router-view/>
  </main>
  
  <footer>
    <Footer />
  </footer>
  
  <ScrollTop />
</template>

<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import ScrollTop from 'primevue/scrolltop';

export default {
  components: {
    Header,
    Footer,
    ScrollTop,
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

body {
  width: 100%;
  color: #999 !important;
  font-size: 15px;
  font-family: 'Josefin Sans', sans-serif !important;
  line-height: 1.80857;
  background-color: #ededed;
}

.background-padrao {
  background-color: blueviolet;
}

ol, ul {
  padding-left: 0 !important;
}

a {
  text-decoration: none !important;
}

.invalid {
  border: 1px solid red !important;
}
</style>
