import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/cadastro',
    name: 'cadastro',
    component: () => import('@/views/Cadastro.vue')
  },
  {
    path: '/politica-privacidade',
    name: 'PoliticaPrivacidade',
    component: () => import('@/components/PoliticaPrivacidade.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router