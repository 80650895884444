<template>
  <div class="home">
    <SectionOne />
    <SectionTwo id="sobre" />
    <SectionThree id="planos" />
  </div>
  
</template>

<script>
import SectionOne from '@/components/SectionOne.vue';
import SectionTwo from '@/components/SectionTwo.vue';
import SectionThree from '@/components/SectionThree.vue';

export default {
  name: 'HomeView',
  components: {
    SectionOne,
    SectionTwo,
    SectionThree,
  }
}
</script>

<style scoped>
</style>
