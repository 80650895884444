<template>
    <section id="section-one" class="shadow-lg">
        <div class="container justify-content-center align-items-center d-flex flex-wrap container-mobile">
            <div class="col-md-6 container-top-mobile">
                <div class="row mb-5">
                    <h1>Novos processos em suas mãos</h1>
                </div>
                <div class="row">
                    <p>Não perca mais nenhum processo, nos lhe traremos todos os processos cadastrados instantaneamente</p>
                </div>
            </div>
            <div class="container-bottom-mobile col-md-6 p-3 justify-content-center align-items-center d-flex" data-aos-duration="2000" data-aos="fade">
                <img src="../assets/img/celular.png" alt="">
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style scoped>
#section-one {
    position: relative;
    height: 700px;
    background-image: url('../assets/img/banner3.png');
    background-repeat: no-repeat;
    background-size: cover;
}

img {
    width: 50%;
}

.container {
    height: 700px;
}

h1, h4, p {
    color: #fff;
}

h1 {
    font-size: 2rem;
}

p {
    font-size: 1.5rem;
}

@media screen and (max-width: 768px) {
    .container-top-mobile {
        margin-top: 30px;
    }

    .container-bottom-mobile {
        margin-bottom: 30px;
    }
}
</style>
