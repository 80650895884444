<template>
   <nav class="navbar navbar-expand-lg background-gradient shadow">
      <div class="container">
         <router-link class="navbar-brand" to="/">
            <img src="@/assets/img/logo_branca.png" style="width: 50px">
         </router-link>
         <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
         >
            <span class="navbar-toggler-icon"></span>
         </button>
         <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
            <ul class="navbar-nav">
               <li class="nav-item">
                  <router-link class="nav-link" to="/">Home</router-link>
               </li>
               <li class="nav-item">
                  <a href="#sobre" class="nav-link">Sobre nós</a>
               </li>
               <li class="nav-item">
                  <a href="#planos" class="nav-link">Planos</a>
               </li>
               <li class="nav-item">
                  <router-link class="nav-link" to="/cadastro">Cadastro</router-link>
               </li>
            </ul>
         </div>
      </div>
   </nav>
</template>

<script>
export default {
   name: "Header",
};
</script>

<style scoped>
.nav-item a,
.navbar-brand {
   color: #fff;
}

.nav-item a:hover,
.navbar-brand:hover {
   opacity: 0.8;
}

.background-gradient {
   background: blueviolet;  /* fallback for old browsers */
   background: -webkit-linear-gradient(to right, blueviolet, #a044ff);  /* Chrome 10-25, Safari 5.1-6 */
   background: linear-gradient(to right, blueviolet, #a044ff); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
</style>
