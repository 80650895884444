import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import AOS from 'aos'
import PrimeVue from 'primevue/config';
import VueTheMask from 'vue-the-mask'
import axios from "axios";
import ToastService from 'primevue/toastservice';

import 'aos/dist/aos.css'
import 'bootstrap/dist/css/bootstrap.css' 
import 'bootstrap/dist/js/bootstrap.bundle';
import "primevue/resources/themes/lara-light-indigo/theme.css";
import '/node_modules/primeflex/primeflex.css'

// ROTA DO BACKEND
axios.defaults.baseURL = 'https://licitalerta.lksoftware.com.br/public/api';
// axios.defaults.baseURL = 'http://127.0.0.1:8000/api/';

const app = createApp(App)
app.config.globalProperties.axios=axios
app.use(store)
app.use(VueTheMask)
app.use(AOS.init())
app.use(router)
app.use(PrimeVue)
app.use(ToastService)
app.mount('#app')
