<template>
   <div id="footer">
      <div class="mt-5">
         <footer
            class="text-white text-center text-lg-start"
            style="background-color: #23242a"
         >
            <div class="container p-4">
               <div class="row mt-4">
                  <div class="col-lg-4 col-md-12 mb-4 mb-md-0">
                     <h5 class="text-uppercase mb-4">LicitAlerta</h5>

                     <p>
                        Conectando oportunidades, impulsionando negócios.<br>
                        Na Licitalerta, transformamos notificações em sucesso
                     </p>

                     <p>
                       
                     </p>

                     <div class="mt-4 mb-4 d-flex justify-content-between col-md-8">
                        <a type="button" class="btn btn-floating btn-lg text-light" style="background-color: blueviolet;"><i class="fab fa-facebook-f"></i></a>
                        <a type="button" class="btn btn-floating btn-lg text-light" style="background-color: blueviolet;"><i class="fab fa-instagram"></i></a>
                        <a type="button" class="btn btn-floating btn-lg text-light" style="background-color: blueviolet;"><i class="fab fa-whatsapp"></i></a>
                     </div>
                  </div>

                  <div class="col-lg-4 col-md-6 mb-4 mb-md-0">
                     <h5 class="text-uppercase mb-4 pb-1">Contatos</h5>

                     <div class="form-outline form-white mb-4">
                        <div class="form-notch">
                           <div class="form-notch-leading" style="width: 9px"></div>
                           <div class="form-notch-middle" style="width: 48.8px"></div>
                           <div class="form-notch-trailing"></div>
                        </div>
                     </div>

                     <ul class="fa-ul" style="margin-left: 1.65em">
                        <li class="mb-3">
                           <span class="fa-li"><i class="fas fa-home"></i></span
                           ><span class="ms-2">Uberlandia, MG</span>
                        </li>
                        <li class="mb-3">
                           <span class="fa-li"><i class="fas fa-envelope"></i></span
                           ><span class="ms-2">LicitAlerta@gmail.com</span>
                        </li>
                        <li class="mb-3">
                           <span class="fa-li"><i class="fas fa-phone"></i></span
                           ><span class="ms-2">+55 (34) 9202-1394 </span>
                        </li>
                     </ul>
                  </div>

                  <div class="col-lg-4 col-md-6 mb-4 mb-md-0">
                     <h5 class="text-uppercase mb-4">Horario de Atendimento</h5>

                     <table class="table text-center text-white">
                        <tbody class="font-weight-normal">
                           <tr>
                              <td>Seg - Sex:</td>
                              <td>08h às 22h</td>
                           </tr>
                           <tr>
                              <td>Sáb - Dom:</td>
                              <td>08h às 13h</td>
                           </tr>
                           <!-- <tr>
                              <td>Sunday:</td>
                              <td>9am - 10pm</td>
                           </tr> -->
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>

            <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.2)">
               © {{ano}} Copyright:
               <span class="text-light">LicitAlerta</span>
            </div>
         </footer>
      </div>
   </div>
</template>

<script>
export default {
   data() {
      return {
         ano: ''
      }
   },
   methods: {
      anoAtual() {
         const ano = new Date();
         
         this.ano = ano.getFullYear();
      }
   },
   mounted() {
      this.anoAtual();
   }
};
</script>

<style></style>
