<template>
   <div id="section-three" style="background: blueviolet">
      <div class="container py-5">
         <!-- FOR DEMO PURPOSE -->
         <header class="text-center mb-5 text-light">
            <div class="row">
               <div class="col-lg-7 mx-auto">
                  <h1>Nossos Planos</h1>
               </div>
            </div>
         </header>
         <!-- END -->

         <div class="row text-center justify-content-center align-items-end">
            <!-- Pricing Table-->
            <div class="col-lg-4 mb-5 mb-lg-0">
               <div class="bg-white p-5 rounded-lg shadow-lg">
                  <h1 class="h6 text-uppercase font-weight-bold mb-4">Plano</h1>
                  <h2 class="h1 font-weight-bold">
                     R$ 49,90<span class="text-small font-weight-normal ml-2">/ mês</span>
                  </h2>

                  <div class="custom-separator my-4 mx-auto bg-primary"></div>

                  <ul class="list-unstyled my-5 text-small text-left">
                     <li class="mb-3 d-flex">
                        <div class="col-md-12 d-flex justify-content-center">
                           <div class="col-md-1 text-end">
                              <i class="fa fa-check me-2 text-primary"></i>
                           </div>
                           <div class="col-md-10 text-center">
                              Notificações em Tempo Real
                           </div>
                        </div>
                     </li>
                     <li class="mb-3 d-flex">
                        <div class="col-md-12 d-flex justify-content-center">
                           <div class="col-md-1 text-end">
                              <i class="fa fa-check me-2 text-primary"></i>
                           </div>
                           <div class="col-md-10 text-center">
                              Acesso Prático a Editais
                           </div>
                        </div>
                     </li>
                     <li class="mb-3 d-flex">
                        <div class="col-md-12 d-flex justify-content-center">
                           <div class="col-md-1 text-end">
                              <i class="fa fa-check me-2 text-primary"></i>
                           </div>
                           <div class="col-md-10 text-center">
                              Mais Oportunidades
                           </div>
                        </div>
                     </li>
                     <li class="mb-3 d-flex">
                        <div class="col-md-12 d-flex justify-content-center">
                           <div class="col-md-1 text-end">
                              <i class="fa fa-check me-2 text-primary"></i>
                           </div>
                           <div class="col-md-10 text-center">
                              Facilidade para Participação
                           </div>
                        </div>
                     </li>
                     <li class="mb-3 d-flex">
                        <div class="col-md-12 d-flex justify-content-center">
                           <div class="col-md-1 text-end">
                              <i class="fa fa-check me-2 text-primary"></i>
                           </div>
                           <div class="col-md-10 text-center">
                              Suporte ao Cliente
                           </div>
                        </div>
                     </li>
                     <li class="mb-3 d-flex">
                        <div class="col-md-12 d-flex justify-content-center">
                           <div class="col-md-1 text-end">
                              <i class="fa fa-check me-2 text-primary"></i>
                           </div>
                           <div class="col-md-10 text-center">
                              Aumente seu Faturamento
                           </div>
                        </div>
                     </li>
                  </ul>
                  <router-link class="btn btn-outline-primary btn-block p-2 shadow-lg" to="/cadastro">Cadastre-se</router-link>
               </div>
            </div>
            <!-- END -->
         </div>
      </div>
   </div>
</template>

<script>
export default {};
</script>

<style scoped>
.rounded-lg {
  border-radius: 1rem !important;
}

.text-small {
  font-size: 0.9rem !important;
}

.custom-separator {
  width: 5rem;
  height: 6px;
  border-radius: 1rem;
}


.text-uppercase {
  letter-spacing: 0.2em;
}

.shadow-color {
   box-shadow: 0 0 10px blueviolet; /* Altere a cor conforme necessário */
   }

</style>
